import React, { useState, useEffect } from "react";
import "./App.css";
// import screenshot1 from "./Screenshot1.jpeg";
// import screenshot2 from "./Screenshot2.jpeg";
// import screenshot3 from "./Screenshot3.jpeg";
// import screenshot4 from "./Screenshot4.jpeg";

import screenshot1 from "./Screen-Recording-2023-02-13-at-8.gif";
import screenshot2 from "./Screen-Recording-2023-02-13-at-9.gif";
import screenshot3 from "./Screen-Recording-2023-02-14-at-6.gif";
import Logo from "./favicon.png";
import instgram from "./assets/instgram.png";
import twitter from "./assets/twitter.png";
import redbook from "./assets/redbook.png";

const App = () => {
  const [ispromptVisible, setPromptVisible] = useState(false);
  const [userAgentText, setUerAgentText] = useState();

  useEffect(() => {
    checkBrowserType();
  }, []);

  const checkBrowserType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/MicroMessenger/gi.test(userAgent)) {
      // Wechat must come first because its UA also contains "iPhone"
      setPromptVisible(true);
    }
  };

  // const getMobileOperatingSystem = () => {
  //   console.log(setUerAgentText(userAgent));
  //   let downloadLink = "https://pub-c4c8405eadc74aa7980eb900cfc9abaf.r2.dev/lolly.apk";

  //   if (/windows phone/i.test(userAgent)) {
  //     // Windows Phone must come first because its UA also contains "Android"
  //     downloadLink = "Windows Phone";
  //   } else if (/MicroMessenger/gi.test(userAgent)) {
  //     // Wechat must come first because its UA also contains "iPhone"
  //     setPromptVisible(true);
  //     // downloadLink = "http://mp.weixin.qq.com/mp/redirect?url=http%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fid399608199%23rd";
  //     return false;
  //   } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //     // downloadLink = "https://apps.apple.com/app/wechat/id414478124";
  //     downloadLink = "https://pub-c4c8405eadc74aa7980eb900cfc9abaf.r2.dev/lolly.apk";
  //   } else if (/android/i.test(userAgent)) {
  //     // Android TODO: oppo vivo huawei xiaomi
  //     // downloadLink = "https://play.google.com/store/apps/details?id=com.wonderfulfrog.lolly";
  //     downloadLink = "https://pub-c4c8405eadc74aa7980eb900cfc9abaf.r2.dev/lolly.apk";
  //   } else {
  //     setPromptVisible(true);
  //     // window.alert(userAgent);
  //     return false;
  //   }
  //   let a = document.getElementById("download_link");
  //   if (a && downloadLink) {
  //     window.location = downloadLink;
  //   }
  // };

  const handleAndroidDownload = () => {
    let downloadLink = "https://pub-c4c8405eadc74aa7980eb900cfc9abaf.r2.dev/lolly.apk";

    let a = document.getElementById("download_link");
    if (a && downloadLink) {
      window.location = downloadLink;
    }
  };
  const handleIosDownload = () => {};

  const handleFollowUsOnClick = (type) => {
    switch (type) {
      case "instgram":
        break;
      case "twitter":
        break;
      case "redbook":
        break;

      default:
        break;
    }
  };

  return (
    <div className="App">
      <div className="title">
        <img className="logo" src={Logo} alt={""} />
        <flex className="titleText">Lolly</flex>
      </div>
      <div className="subTitle">Your Map, Your Friends</div>
      <div className="imgContainer">
        <img src={screenshot1} alt={""} className="screenshot" />
        <img src={screenshot2} alt={""} className="screenshot" />
        <img src={screenshot3} alt={""} className="screenshot" />
      </div>
      <div className="buttonContainer">
        <button
          disabled={ispromptVisible}
          onClick={() => {
            handleAndroidDownload();
          }}
          id="download_link"
          className="button"
        >
          {ispromptVisible ? "Please Open Your Browser To Download" : "Android Avaiable To Download"}
        </button>
      </div>
      <div className="note">iOS Coming Soon</div>
      {/* <div id="foot">
        <img src={instgram} alt={""} className="followUs" onClick={handleFollowUsOnClick("instgram")} />
        <img src={twitter} alt={""} className="followUs" onClick={handleFollowUsOnClick("twitter")} />
        <img src={redbook} alt={""} className="followUs" onClick={handleFollowUsOnClick("redbook")} />
      </div> */}
    </div>
  );
};

export default App;
